[contenteditable] {
  outline: 0px solid transparent;
}

/* .editableDivStyle img {
  width: auto;
} */
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: lightgray;
  background-color: transparent;
}

.ant-popover-arrow {
  display: none;
}
.sticktotop {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  margin-top: 0px;
  padding: 0px;
}

.postBody {
  max-height: 400px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.readMoreDiv {
  text-align: center;
  margin: "20px 0px";
}
