body {
  font-family: "Montserrat";
}

.iconStyle {
  //   color: #f99b1e;
  color: #1890ff;
  font-size: 20;
  &:hover {
    color: white;
  }
}

.shadow-down {
  -webkit-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
}

.shadow-right {
  -webkit-box-shadow: 10px 0px 12px -8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 0px 12px -8px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0px 12px -8px rgba(0, 0, 0, 0.1);
}

.header-background {
  background: #ffffff; /* fallback for old browsers */
  // background: -webkit-linear-gradient(
  //   to right,
  //   #186ab4,
  //   #00dbde
  // ); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(
  //   to right,
  //   #186ab4,
  //   #00dbde
  // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.color-button {
  padding: 8px 20px;
  margin: 0px 10px;
  border-radius: 50px;
  color: white;
  // width: 200px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background: #7474bf; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #348ac7,
    #7474bf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #348ac7,
    #7474bf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  &:hover {
    background: white;
    color: #348ac7;
    -webkit-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
    box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
  }
}

.follow-button {
  color: white;
  font-weight: bold;
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
  background: #7474bf; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #348ac7,
    #7474bf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #348ac7,
    #7474bf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  &:hover {
    background: -webkit-linear-gradient(
      to left,
      #348ac7,
      #7474bf
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #348ac7, #7474bf);
    color: white;
    -webkit-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
    box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
  }
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.trend-header {
  padding: 20px;
  // background: -webkit-linear-gradient(
  //   to right,
  //   #348ac7,
  //   #7474bf
  // ); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #348ac7, #7474bf);
  // border-style: dashed;
  // border-width: 1px;
  // border-color: #e9ebee;
  background-color: #78909c;
  // border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.tier-card {
  cursor: pointer;
  &:hover {
    background-color: #e3f2fd;
  }
}

.tier-card-subscribed {
  background-color: #e3f2fd;
  // &:hover {
  //   background-color: #e3f2fd;
  // }
}

.edit-tiers {
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.ql-video {
  width: 100%;
  height: 300px;
}
.postbody img {
  width: 100%;
}

.no-border {
  .ant-select-selection {
    border-style: none;
  }
}

.myPopover {
  .ant-popover-inner-content {
    padding: 0px 0px;
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-popover-inner-content {
  padding: 0px 0px;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.65);
}

.editor-container1 {
  height: 100%;
  /* added these styles */
  flex: 1;
  display: flex;
  flex-direction: column;
}

.editor1 {
  height: 100%;
  /* added these styles */
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
.container1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.side-bg {
  background-image: url("../img/connect.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.underline-input {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  color: white;
  &:hover {
    border-width: 0px;
    border-color: transparent;
  }
  &:focus {
    border-color: white;
    border-width: 1px;
  }
}
.underline-input1 {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  &:hover {
    border-width: 0px;
    border-color: transparent;
  }
  &:focus {
    border-color: white;
    border-width: 1px;
  }
}

.login-button {
  width: 100%;
  height: 70px;

  background: #009efc;
  border: 1px solid #009efc;
  box-sizing: border-box;
  border-radius: 20px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}
.small-primary-button {
  // width: 246.14px;
  height: 70px;
  width: 100%;
  background: #009efc;
  border-radius: 15px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}
.small-ghost-button {
  // width: 246.23px;
  width: 100%;
  height: 70px;

  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;

  // height: 15px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  // line-height: 24px;

  color: #999999;
}

.signup-button {
  width: 100%;
  height: 70px;

  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  border-radius: 20px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #999999;
}

.login-input {
  // position: absolute;
  width: 100%;
  height: 70px;
  // padding: 0px 20px;
  // background: #ffffff;
  // border: 0.5px solid #ededed;
  // border-radius: 20px;

  // height: 83px;

  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 15px;

  // transform: matrix(1, 0, 0, -1, 0, 0);
}
.already-registered {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #999999;
}
.side-text {
  width: 343px;
  // height: 196px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  // line-height: 80px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.sub-heading {
  width: 462px;
  height: 12px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  color: #999999;
}
.nav-search {
  width: 336px;
  height: 42px;

  background: #ffffff;
  border: 0.5px solid #ededed;
  border-radius: 25px;
  // transform: rotate(-180deg);
}
.nav-button1 {
  width: 140px;
  height: 42px;

  background: #009efc;
  border-radius: 25px;
  color: white;
}
.button1text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
.nav-button2 {
  width: 110px;
  height: 42px;
  margin-left: -10px;
  background: #ffffff;
  border: 0.5px solid #ededed;
  border-radius: 25px;
}
.button2text {
  width: 65.27px;
  height: 8.38px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #999999;
}
.nav-userbutton {
  width: 180px;
  height: 42px;
  cursor: pointer;
  background: #ffffff;
  border: 0.5px solid #ededed;
  border-radius: 25px;
  // transform: rotate(-180deg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.nav-userbutton-expand {
  width: 184px;
  height: 160px;
  display: block;
  background: #ffffff;
  border: 0.5px solid #ededed;
  border-radius: 25px;
  // transform: rotate(-180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  // padding: 0px 10px;

  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}
.nav-menu-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
}
.nav-menu-text1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.nav-item {
  display: flex;
  // justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
 
  &:hover {
    background-color: #def2ff;
    color: #009efc;
    cursor: pointer;
    
  }
}

.nav-item1 {
  display: flex;
  // justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  color: #999999;
  // padding: 30px;
  width: 200px;
  border: 1px solid rgba(153, 153, 153, 0.3); 
  
  &:hover {
    background-color: #def2ff;
    color: #009efc;
    cursor: pointer;
    border: 1px solid rgba(0, 158, 252, 0.3); 
    .nav-menu-text1 {
      color: #009efc;
    }
  }
}

.nav-item1-selected{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #009efc;
  // padding: 30px;
  width: 200px;
  border: 1px solid rgba(0, 158, 252, 0.3); 
  &:hover {
    background-color: #009efc;
    color: white;
    cursor: pointer;
    .nav-menu-text1 {
      color: #009efc;
    }
}}

.ant-tab-new {
  .ant-tabs-nav-wrap {
    margin: 0px;
    flex: 100%;
  }
}

.nav-profile-button {
  // position: absolute;
  // width: 86px;
  // height: 10px;
  // left: 1275px;
  // top: 31px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;

  color: #999999;
}
.selected-profile-menu {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;

  color: #5f6163;
  &:hover {
    cursor: pointer;
  }
}
.unselected-profile-menu {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #5f6163;

  opacity: 0.3;
  &:hover {
    cursor: pointer;
    opacity: 1;
    // color: #009efc;
    // font-weight: 800;
    // font-size: 14px;
    // line-height: 17px;

    // color: #5f6163;
  }
}

.selected-profile-tab {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #999999;

  &:hover {
    cursor: pointer;
  }
}

.unselected-profile-tab {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #999999;
  opacity: 0.3;
  &:hover {
    cursor: pointer;
  }
}

.unselected-side-menu {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  // line-height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  // border-bottom: 0.5px solid #ededed;

  padding: 2px 0px;

  // color: #ededed;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    color: #009efc;
    // background-color: #d9d9d9;
  }
}

.tab-item1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  // line-height: 18px;
  display: flex;
  align-items: center;

  color: #999999;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    color: #009efc;
  }
}
.tab-item2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  // line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #5f6163;
}

.tab-button1 {
  width: 100%;
  height: 43px;

  background: #009efc;
  border-radius: 25px;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}
.tab-button-red {
  width: 100%;
  height: 43px;
  color: red;
  // background: red;
  border-radius: 25px;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}
.profile-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #5f6163;
}

.hoverstyle {
  transition: transform 0.5s ease;
  // padding-left: 7px;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.hoverstyle1 {
  transition: transform 0.5s ease;
  // padding-left: 7px;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
.logoutText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.spaceitem {
  .ant-space-item {
    z-index: 999;
  }
}
.mobile-login {
  width: 100%;
  height: 61px;

  background: #009efc;
  border: 1px solid #009efc;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}

.mobile-back {
  width: 100%;
  height: 61px;

  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #999999;
}

.mobile-register {
  width: 100%;
  height: 61px;

  background: #ffffff;
  border: 0.5px solid #999999;
  box-sizing: border-box;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #999999;
}
.login-mobile-input1 {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  // width: 313px;
  height: 71px;
}
.input-placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.disabled-button {
  // position: absolute;
  width: 100%;
  height: 61px;

  background: #009efc;
  border: 1px solid #009efc;
  box-sizing: border-box;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}
.full-loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 99999999;
  .full-loading-logo {
    // height: 65px;
    animation: leaves 0.75s infinite alternate;
  }
}

@keyframes leaves {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
.makeacard {
  background: white;
  // color: #348ac7;
  -webkit-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 6px 20px -13px rgba(0, 0, 0, 0.47);
}
.landing-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 45px;
  /* or 237% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #5f6163;
}
.indian-market-button {
  width: 522px;
  height: 133px;
  left: 747px;
  top: 387px;

  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  .img-animate {
    transition: 0.3s all ease-in-out;
  }
  &:hover {
    cursor: pointer;
    // border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    transition: box-shadow 0.3s ease-in-out;
    .img-animate {
      transform: scale(1.05);
    }
  }
}
.landing-right {
  height: 100vh;
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 5% 14%;
}

.landing-blocks {
  // position: absolute;
  width: 140px;
  height: 133px;
  // left: 747px;
  // top: 601px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  .img-animate {
    transition: 0.3s all ease-in-out;
  }
  &:hover {
    cursor: pointer;
    // border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    transition: box-shadow 0.3s ease-in-out;
    .img-animate {
      transform: scale(1.1);
    }
  }
}

.landing-blocks-mobile {
  // position: absolute;
  width: 105px;
  height: 100px;
  // left: 747px;
  // top: 601px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  .img-animate {
    transition: 0.3s all ease-in-out;
  }
  &:hover {
    cursor: pointer;
    // border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    transition: box-shadow 0.3s ease-in-out;
    .img-animate {
      transform: scale(1.1);
    }
  }
}

.landing-blocks-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 45px;
  /* or 321% */

  text-align: center;

  color: #5f6163;
}

.fixed-widget-bottm-right{
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 2147483640;
  display: flex;
  flex-direction: column;
  cursor: pointer;


    // border-radius: 15px;
    .img-animate {
      transition: 0.3s all ease-in-out;
    }
    &:hover {
      cursor: pointer;
      border-radius: 100%;
      background: #ffffff;
      box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
      transition: box-shadow 0.3s ease-in-out;
      .img-animate {
        transform: scale(1.1);
      }
    }
  


}

.neu-shadow{
  border-radius: 30%;
background: #ffffff;
box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}